import './Story.css';
import { PrismicImage, PrismicRichText, usePrismicDocumentByUID, useSinglePrismicDocument } from '@prismicio/react'
import { useParams } from 'react-router-dom';

function Story() {
  const { id } = useParams(); 
  const [document] = usePrismicDocumentByUID('story_page', id ? id : "")
  
  console.log(document)
  if (!document){
    return <h1>Loading...</h1>
  }
  return (
    <div>
        <PrismicRichText field={document.data.title}/>
        <PrismicRichText field={document.data.subtitle} />
        <PrismicImage field={document.data.header_image} />
        <PrismicRichText field={document.data.body} />
    </div>
  );
}

export default Story;