import './App.css';
import './Navbar.css';
import {PrismicLink, useSinglePrismicDocument, useAllPrismicDocumentsByType } from '@prismicio/react'

function Navbar() {
  const [documents] = useAllPrismicDocumentsByType('story_page')
  const [document] = useSinglePrismicDocument('start_page')
  console.log(documents)
  if (!documents){
    return <></>
  }
  return (
    <div>
        <PrismicLink document={document}>{'start'}</PrismicLink>
        {documents.map(element =>{
            return(<PrismicLink document={element}>{element.data.title[0].text ?? 'Missing Title'}</PrismicLink>)
        })}
    </div>
  );
}

export default Navbar;
