import './App.css';
import {PrismicRichText, useSinglePrismicDocument } from '@prismicio/react'

function Start() {
  const [document] = useSinglePrismicDocument('start_page')
  console.log(document)
  if (!document){
    return <h1>Loading...</h1>
  }
  return (
    <div>
        <PrismicRichText field={document.data.title} />
        <PrismicRichText field={document.data.subtitle} />
    </div>
  );
}

export default Start;
