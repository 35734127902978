import './App.css';
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Start from './start_page';
import Story from './story_page';
import Navbar from './Navbar';

function App() {
  return (
<>
    <Navbar/>
    <Router>
        <Routes>
          <Route path={`/story/:id/`} element={<Story />}/>
          <Route path='/' element={<Start />}/>
        </Routes>
    </Router>
  </>);
}

export default App;
